// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-de-cv-js": () => import("./../src/pages/de/cv.js" /* webpackChunkName: "component---src-pages-de-cv-js" */),
  "component---src-pages-en-cv-js": () => import("./../src/pages/en/cv.js" /* webpackChunkName: "component---src-pages-en-cv-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

